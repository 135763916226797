import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
const initializeGA = () => {
    ReactGA.initialize('G-WHTBQ5BD34');
};
const initializeGTM = () => {
    TagManager.initialize({ gtmId: 'GTM-T4RF4XFF' });
};
const logPageView = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
};

export { initializeGA, logPageView, initializeGTM };
